.mainContainer {
  position: relative;
}

.mainContainer .background {
  /* background-image: url("./image/back.svg"); */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.mainContainer .article-container {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 320px;
  color: #343434ff;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-container h1 {
  font-weight: 800;
  font-size: 6em;
}

.article-container .introduction {
  margin-top: 50px;
  color: "#78787fff";
}

.article-container #maincopy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.article-wrap {
  margin: 30px;
}

.article-wrap .centered {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.title-line {
  height: 5px;
  margin-top: 20px;
  background: black;
  width: 10em;
}

.mainContainer .image-container {
  margin-top: 30px;
  margin-left: 15%;
}

.image-container img {
  margin-right: 20px;
}

.guideSectionTitle {
  font-style: italic;
  text-align: center;
}

.guideImageContainer img {
  background-color: red;
  margin-right: 50px;
}

#userguide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

#userguide h2 {
  margin-top: 150px;
  margin-bottom: 0px;
}

#userguide .title-line {
  height: 2px;
  background: black;
  width: 15em;
  margin-bottom: 50px;
}

.guidePart p {
  font-size: 1em;
  align-content: center;
  text-align: center;
}

.guideSection {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.guidePart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-download-btn {
  margin-top: 80px;
}

.app-download-btn a {
  font-size: 3em;
  margin-right: 20px;

}

.app-download-btn {
  display: flex;
  flex-direction: row;
  width: 500px;
}
