.mobile-nav-container {
  padding-top: 30px;
  position: fixed;
  top: 0px;
  width: 100%;
  display: flex;
  height: 60px;
  flex-direction: row;
  justify-content: center;
}

.mobile-nav-container .content-wrap {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  margin-left: 30px;
  margin-right: 10px;
  align-items: center;
  flex-direction: row;
}

.mobile-nav-container #logo h1 {
  font-size: 1.4em;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: black;
  text-align: center;
  height: 10px;
}

.mobile-nav-container .menu-nav {
  margin-right: 10px;
}

.mobile-nav-container .menu-nav ul {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  box-sizing: content-box;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  align-items: center;
  color: black;
  list-style-type: none;
}

.mobile-nav-container .download-btn img {
  width: 100px;
}
