.nav-container {
  padding-top: 30px;
  position: fixed;
  top: 0px;
  width: 100%;
  display: flex;
  height: 60px;
  flex-direction: row;
  justify-content: center;
}


.nav-container .content-wrap {
  width: 70%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.nav-container #logo h1 {
  font-size: 1.4em;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: black;
  text-align: center;
}

.menu-nav ul {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  box-sizing: content-box;
  display: flex;
  width: 250px;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  align-items: center;
  color: black;
  list-style-type: none;
}

.download-btn {
  width: 100px;
  background-position: center;
}

.download-btn img {
  width: 130px;
}
