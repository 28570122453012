.mobileContainer {
  position: relative;
}

.mobileContainer .background {
  /* background-image: url("./image/back.svg"); */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.mobileContainer .article-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  color: #343434ff;
  font-weight: 800;
  display: flex;
  /* align-items: center; */
  margin-top: 100px;
}

.mobileContainer .article-container h1 {
  font-weight: 800;
  font-size: 3em;
}

.article-wrap {
  margin: 0;
}

.mobileContainer .article-container .introduction {
  margin-top: 50px;
  color: "#78787fff";
  width: 100%;
  font-size: 1em;
}

.mobileContainer .article-container #maincopy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.mobileContainer .article-wrap .centered {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.mobileContainer .title-line {
  height: 5px;
  margin-top: 20px;
  background: black;
  width: 10em;
}

.mobileContainer .image-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mobileContainer .content-wrap {
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.mobileContainer .guideSectionTitle {
  font-style: italic;
  text-align: center;
}

.mobileContainer .guideImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.mobileContainer .guideImageContainer img {
  margin-right: 0px;
}

.mobileContainer #userguide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.mobileContainer #userguide h2 {
  margin-top: 80px;
  margin-bottom: 0px;
}

.mobileContainer #userguide .title-line {
  height: 2px;
  background: black;
  width: 15em;
  margin-bottom: 10px;
}

.mobileContainer .guidePart p {
  font-size: 1em;
  align-content: center;
  text-align: center;
}

.mobileContainer .guideSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.mobileContainer .guidePart {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: ; */
  margin-top: 50px;
}

/* .mobileContainer .app-download-btn {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
} */

.mobileContainer #maincopy h1 {
  margin: 0 0 0 0;
}

.mobileContainer #maincopy h2 {
  margin-top: 30px;
}

.mobileContainer .app-download-btn {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.mobileContainer .app-download-btn {
  margin-top: 45px;
}

.mobileContainer .app-download-btn a {
  margin-right: 0px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mobileContainer .image-container img {
  margin-left: auto;
  margin-right: auto;
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
}

.mobileContainer .app-download-btn a img {
  /* margin-left: auto; */
  /* margin-right: auto; */

  width: 100%;
}
